import { useMutation, UseMutationResult } from 'react-query'
import { AxiosError } from 'axios'

import { fetchAPIv2 } from '@/lib/api'
import { Policy } from '../types'

type Payload = {
  data: {
    template: string
    email?: string
    profile_id?: string
  }
}

type Response = {
  policy: Policy
}

export const useCreateFirstStep = (): UseMutationResult<
  Policy,
  AxiosError,
  Payload
> => {
  return useMutation<Policy, AxiosError, Payload>(
    async ({ data }): Promise<Policy> => {
      const response = await fetchAPIv2<Response>({
        path: '/policies',
        method: 'post',
        data,
      })

      return response.data?.policy
    },
  )
}
