import { useEffect } from 'react'
import {
  VStack,
  Flex,
  Spacer,
  Text,
  Collapse,
  useDisclosure,
} from '@chakra-ui/react'
import * as Cookies from 'js-cookie'

import { COOKIE_HIDE_TRANSPARENCY_BANNER } from '../constants'

function TransparencyBanner(): React.ReactElement {
  const { isOpen, onOpen, onClose } = useDisclosure()

  useEffect(() => {
    const cookie = Cookies.get(COOKIE_HIDE_TRANSPARENCY_BANNER)

    if (!cookie) {
      onOpen()
    }
  }, [])

  return (
    <Collapse in={isOpen}>
      <Flex
        sx={{
          flexFlow: {
            base: 'column nowrap',
            md: 'row nowrap',
          },
          justifyContent: 'center',
          alignItems: {
            base: 'flex-start',
            md: 'center',
          },
          p: 4,
          bgColor: '#16202b',
          borderRadius: 'md',
          mt: 4,
          color: 'white',
        }}
      >
        <VStack sx={{ alignItems: 'left' }}>
          <Text sx={{ fontSize: '14px', fontWeight: 'medium' }}>
            การบันทึกข้อมูลอัตโนมัติในการสร้างนโยบาย
          </Text>
          <Text
            sx={{
              fontSize: '12px',
              fontWeight: 'medium',
              color: 'JUMBO_GRAY',
              fontFamily: 'sans-serif',
            }}
          >
            เราบันทึกข้อมูลที่กรอกอัตโนมัติเพื่อประสบการณ์การใช้งานของคุณเท่านั้น
            เราจะไม่นำข้อมูลไปใช้หรือเปิดเผย หากคุณไม่ต้องการให้เราเก็บข้อมูล
            คุณสามารถลบ Policy ได้ในภายหลัง
          </Text>
        </VStack>
        <Spacer sx={{ minW: 4 }} />
        <Text
          onClick={() => {
            Cookies.set(
              COOKIE_HIDE_TRANSPARENCY_BANNER,
              new Date().getTime().toString(),
              {
                expires: new Date(2200, 1),
              },
            )

            onClose()
          }}
          sx={{
            fontSize: '12px',
            fontWeight: 'medium',
            whiteSpace: 'pre',
            textDecoration: 'underline',
            cursor: 'pointer',
            '&:hover': {
              textDecoration: 'none',
            },
            pb: {
              base: 0,
              md: 2,
            },
            pt: 2,
          }}
        >
          ไม่ต้องแสดงข้อความนี้อีก
        </Text>
      </Flex>
    </Collapse>
  )
}

export default TransparencyBanner
