import { Fragment } from 'react'
import { Text } from '@chakra-ui/react'

import { ReactComponent as CCTVLogo } from '@/images/Landing/icon_cctv.svg'
import { ReactComponent as CookieLogo } from '@/images/Landing/icon_cookie.svg'
import { ReactComponent as DpaLogo } from '@/images/Landing/icon_dpa.svg'
import { ReactComponent as PrivacyLogo } from '@/images/Landing/icon_privacy.svg'
import { ReactComponent as HrPolicyLogo } from '@/images/Landing/icon_hr_privacy.svg'
import { ReactComponent as RecruitmentPolicyLogo } from '@/images/Landing/icon_recruitment_privacy.svg'
import { ReactComponent as VendorLogo } from '@/images/Landing/icon_vendor.svg'

export const policyContents: Record<
  string,
  {
    title: string
    icon: React.FC<React.SVGAttributes<SVGElement>>
    content: React.ReactElement
    requiredDocuments: string[]
  }
> = {
  cookie: {
    title: 'Cookie Policy',
    icon: CookieLogo,
    content: (
      <Fragment>
        <Text>
          สำหรับเว็บไซต์ที่ใช้คุกกี้เก็บข้อมูลส่วนบุคคลของผู้ใช้งาน
          เพื่อเก็บข้อมูลที่ระบุตัวตนได้ของผู้ใช้งาน เช่น IP Address,
          ข้อมูลการล็อกอินรวมถึงคุกกี้สำหรับการวัดผลต่างๆ เช่น Google Analytics
          หรือ Facebook Pixel
        </Text>
        <Text>
          คำตอบจะถูกบันทึกโดยอัตโนมัติ
          คุณสามารถกลับเข้ามาทำต่อได้เมื่อทำการล็อกอินเข้าสู่ระบบในครั้งถัดไป
        </Text>
      </Fragment>
    ),
    requiredDocuments: [
      'ข้อมูลบริษัท',
      'ประเภทของคุกกี้',
      'แบนเนอร์คุกกี้',
      'ผู้ควบคุมข้อมูลส่วนบุคคล (Data Controller)',
      'เจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคล (Data Protection Officer: DPO)',
    ],
  },
  cctv: {
    title: 'CCTV Policy',
    icon: CCTVLogo,
    content: (
      <Fragment>
        <Text>
          สำหรับธุรกิจที่มีการติดตั้งกล้องโทรทัศน์วงจรปิด (CCTV)
          ไม่ว่าจะในที่ทำงาน ร้านค้า โรงงาน ฯลฯ
          ซึ่งเป็นการเก็บข้อมูลส่วนบุคคลประเภทรูปถ่ายและภาพเคลื่อนไหวใบหน้าของบุคคล
        </Text>
        <Text>
          คำตอบจะถูกบันทึกโดยอัตโนมัติ
          คุณสามารถกลับเข้ามาทำต่อได้เมื่อทำการล็อกอินเข้าสู่ระบบในครั้งถัดไป
        </Text>
      </Fragment>
    ),
    requiredDocuments: [
      'ข้อมูลบริษัท',
      'ข้อมูลบริษัท',
      'การจัดเก็บข้อมูลจากกล้องวงจรปิด',
      'สถานที่เก็บข้อมูลจากกล้องวงจรปิด',
      'การเปิดเผยข้อมูลจากกล้องวงจรปิด',
      'ระยะเวลาการเก็บข้อมูลจากกล้องวงจรปิด',
      'ผู้ควบคุมข้อมูลส่วนบุคคล (Data Controller)',
      'เจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคล (Data Protection Officer: DPO)',
    ],
  },
  privacy: {
    title: 'Privacy Policy',
    icon: PrivacyLogo,
    content: (
      <Fragment>
        <Text>
          สำหรับธุรกิจที่มีการเก็บรวบรวม ใช้
          หรือเปิดเผยข้อมูลส่วนบุคคลของลูกค้าหรือผู้ใช้งาน เช่น ชื่อ-นามสกุล
          อีเมล หรือเบอร์โทรศัพท์
        </Text>
        <Text>
          คำตอบจะถูกบันทึกโดยอัตโนมัติ
          คุณสามารถกลับเข้ามาทำต่อได้เมื่อทำการล็อกอินเข้าสู่ระบบในครั้งถัดไป
        </Text>
      </Fragment>
    ),
    requiredDocuments: [
      'ข้อมูลบริษัท',
      'ข้อมูลส่วนบุคคลของผู้ใช้งาน',
      'ข้อมูลอ่อนไหว',
      'สถานที่เก็บข้อมูลผู้ใช้งาน',
      'จุดประสงค์ที่นำข้อมูลไปใช้',
      'การเปิดเผยข้อมูลส่วนบุคคลของผู้ใช้งาน',
      'การนำข้อมูลไปใช้',
      'การใช้คุกกี้',
      'ผู้ควบคุมข้อมูลส่วนบุคคล (Data Controller)',
      'เจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคล (Data Protection Officer: DPO)',
    ],
  },
  hr_privacy: {
    title: 'HR Privacy Policy',
    icon: HrPolicyLogo,
    content: (
      <Fragment>
        <Text>
          สำหรับธุรกิจที่เก็บรวบรวมข้อมูลส่วนบุคคลของ พนักงาน
          หรือประมวลผลข้อมูลส่วนบุคคล ของพนักงานเช่น ชื่อ-นามสกุล ที่อยู่
          เลขบัตรประชาชน ของพนักงานเพื่อใช้ยื่นภาษี หรือประกันสังคม
        </Text>
        <Text>
          คำตอบจะถูกบันทึกโดยอัตโนมัติ
          คุณสามารถกลับเข้ามาทำต่อได้เมื่อทำการล็อกอินเข้าสู่ระบบในครั้งถัดไป
        </Text>
      </Fragment>
    ),
    requiredDocuments: [
      'ข้อมูลบริษัท',
      'วิธีการเก็บรวบรวมข้อมูลส่วนบุคคลของพนักงาน',
      'ประเภทข้อมูลส่วนบุคคลของพนักงาน',
      'สถานที่เก็บข้อมูลส่วนบุคคลของพนักงาน',
      'การนำข้อมูลไปใช้',
      'การเปิดเผยข้อมูลส่วนบุคคลของพนักงาน',
      'ผู้ควบคุมข้อมูลส่วนบุคคล (Data Controller)',
      'เจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคล (Data Protection Officer: DPO)',
    ],
  },
  dpa: {
    title: 'Data Processing Agreement (DPA)',
    icon: DpaLogo,
    content: (
      <Fragment>
        <Text>
          หากธุรกิจของคุณมีการว่าจ้างผู้ประมวลผลข้อมูลส่วนบุคคล เช่น บริษัท A
          ว่าจ้างให้ บริษัท B ทำการจัดการเงินเดือนให้พนักงาน (Payroll Service)
          ของบริษัท A โดยใช้ข้อมูลส่วนบุคคลของพนักงานบริษัท A ในกรณีนี้
          ทั้งบริษัท A และ B จะต้องจัดทำ Data Processing Agreement
          หรือสัญญาประมวลผลข้อมูลส่วนบุคคลระหว่างกัน
          เพื่อควบคุมการดำเนินงานตามหน้าที่กำหนดความรับผิดชอบของผู้ประมวลผลข้อมูลส่วนบุคคลให้เป็นไปตาม
          PDPA
        </Text>
        <Text>
          คำตอบจะถูกบันทึกโดยอัตโนมัติ
          คุณสามารถกลับเข้ามาทำต่อได้เมื่อทำการล็อกอินเข้าสู่ระบบในครั้งถัดไป
        </Text>
      </Fragment>
    ),
    requiredDocuments: ['ข้อมูลบริษัท', 'ข้อมูลคู่สัญญา', 'ชื่อสัญญา (หลัก)'],
  },
  recruitment_privacy: {
    title: 'Recruitment',
    icon: RecruitmentPolicyLogo,
    content: (
      <Fragment>
        <Text>
          สำหรับองค์กรที่มีการเก็บรวบรวมหรือประมวลผล
          ข้อมูลส่วนบุคคลของผู้สมัครงาน เช่น ชื่อ-นามสกุล ประวัติการทำงาน
          ประวัติการศึกษา ประวัติสุขภาพ ไปจนถึงประวัติอาชญากรรม
          เพื่อเป็นหลักฐานใน การพิจารณาและคัดเลือกเพื่อว่าจ้างต่อไป
        </Text>
        <Text>
          คำตอบจะถูกบันทึกโดยอัตโนมัติ
          คุณสามารถกลับเข้ามาทำต่อได้เมื่อทำการล็อกอินเข้าสู่ระบบในครั้งถัดไป
        </Text>
      </Fragment>
    ),
    requiredDocuments: [
      'ข้อมูลบริษัท',
      'การจัดเก็บข้อมูลส่วนบุคคลของผู้สมัครงาน',
      'ประเภทข้อมูลส่วนบุคคลของผู้สมัครงาน',
      'สถานที่เก็บข้อมูลส่วนบุคคลของผู้สมัครงาน',
      'การนำข้อมูลไปใช้',
      'การเปิดเผยข้อมูลส่วนบุคคลของผู้สมัครงาน',
      'การโอนข้อมูลส่วนบุคคลของผู้สมัครงานไปต่างประเทศ',
      'ผู้ควบคุมข้อมูลส่วนบุคคล (Data Controller)',
      'เจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคล (Data Protection Officer: DPO)',
    ],
  },
  vendor: {
    title: 'Vendor Privacy Policy',
    icon: VendorLogo,
    content: (
      <Fragment>
        <Text>
          สำหรับองค์กรที่มีการเก็บรวบรวม ใช้ หรือเปิดเผย
          ข้อมูลส่วนบุคคลของคู่ค้าหรือผู้ให้บริการ
          เมื่อองค์กรมีการทำสัญญาหรือธุรกรรมร่วมกับคู่ค้า
          หรือเมื่อองค์กรได้มีการใช้บริการจากผู้ให้บริการ เช่น ชื่อ นามสกุล
          ข้อมูลทางการเงิน เพื่อนำมาใช้ใน กระบวนการประเมินการจัดซื้อจัดจ้าง
          เป็นต้น
        </Text>
        <Text>
          คำตอบจะถูกบันทึกโดยอัตโนมัติ
          คุณสามารถกลับเข้ามาทำต่อได้เมื่อทำการล็อกอินเข้าสู่ระบบในครั้งถัดไป
        </Text>
      </Fragment>
    ),
    requiredDocuments: [
      'ข้อมูลบริษัท',
      'การจัดเก็บข้อมูลส่วนบุคคลของคู่ค้า',
      'ประเภทข้อมูลส่วนบุคคลของคู่ค้า',
      'สถานที่เก็บข้อมูลส่วนบุคคลของคู่ค้า',
      'การนำข้อมูลไปใช้',
      'การเปิดเผยข้อมูลส่วนบุคคลของคู่ค้า',
      'การโอนข้อมูลส่วนบุคคลของคู่ค้าไปยังต่างประเทศ',
      'ผู้ควบคุมข้อมูลส่วนบุคคล (Data Controller)',
      'เจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคล (Data Protection Officer: DPO)',
    ],
  },
}
