import React from 'react'
import { Box, Image, Link as ChakraLink } from '@chakra-ui/react'

export const ProkitBanner = (): React.ReactElement => {
  return (
    <Box
      sx={{
        mt: {
          base: '32px',
          md: '48px',
          lg: '64px',
        },
      }}
    >
      <ChakraLink isExternal href="https://pdpa.pro/prokit">
        <picture>
          <source
            media="(min-width: 1024px)"
            srcSet="/images/Form/prokit-banner-desktop.png"
          />
          <source
            media="(min-width: 768px)"
            srcSet="/images/Form/prokit-banner-tablet.png"
          />
          <source srcSet="/images/Form/prokit-banner-mobile.png" />
          <Image
            src="/images/Form/prokit-banner-mobile.png"
            alt="pdpa-prokit"
          />
        </picture>
      </ChakraLink>
    </Box>
  )
}
