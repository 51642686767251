import React from 'react'
import { Flex } from '@chakra-ui/react'
import { Span } from '@/components/shared'
import { Colors } from '@/lib/styles'

export const CustomerProfileBanner = (
  props: CustomerProfileBannerProps,
): React.ReactElement => {
  const { profileName } = props

  return (
    <Flex
      sx={{
        alignItems: 'center',
        bg: Colors.DARK_GREY,
        borderRadius: '4px',
        color: 'white',
        fontSize: 'sm',
        fontWeight: 'light',
        px: 4,
        py: 2,
      }}
    >
      ข้อมูลเริ่มต้นจากโปรไฟล์&nbsp;
      <Span sx={{ fontWeight: 'normal' }}>{profileName}</Span>
    </Flex>
  )
}
type CustomerProfileBannerProps = {
  profileName: string
}
