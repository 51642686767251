import { AxiosError } from 'axios'
import { useMutation, useQueryClient } from 'react-query'
import { useToast } from '@chakra-ui/react'

import { fetchAPIv2 } from '@/lib/api'
import { useErrorHandler } from '@/lib/hooks'

import { PROFILES } from '../constants'
import { TOAST_CONFIG } from '@/lib/constants'

type Payload = {
  data: unknown
  params: {
    id: string
  }
}

export const useUpdateProfile = () => {
  const queryClient = useQueryClient()
  const toast = useToast()
  const handleError = useErrorHandler()

  return useMutation<void, AxiosError, Payload>(
    async ({ data, params }) => {
      await fetchAPIv2<void>({
        path: `/profiles/${params.id}`,
        method: 'put',
        data,
      })
    },
    {
      onSuccess() {
        queryClient.invalidateQueries([PROFILES])

        toast({
          ...TOAST_CONFIG,
          description: 'อัปเดทโปรไฟล์สำเร็จ',
        })
      },
      onError(error) {
        handleError(error)
      },
    },
  )
}
