import { useQuery, useQueryClient, UseQueryResult } from 'react-query'

import { fetchAPIv2 } from '@/lib/api'
import { useAuthContext } from '@/lib/auth/AuthContext'

import { Profile } from '../types'
import { PROFILES } from '../constants'

export const useProfiles = (): UseQueryResult<Profile[]> => {
  const { isAuth } = useAuthContext()

  return useQuery<Profile[]>({
    queryKey: [PROFILES],
    queryFn: async () => {
      const { data } = await fetchAPIv2<{ profiles: Profile[] }>({
        path: '/profiles',
      })

      return data.profiles ?? []
    },
    enabled: isAuth,
  })
}

export const useProfilesData = () => {
  const queryClient = useQueryClient()

  return queryClient.getQueryData<Profile[]>([PROFILES])
}
