import React from 'react'
import { get } from 'lodash'
import { Box, Flex, Heading, Grid } from '@chakra-ui/react'
import { useRouter } from 'next/router'

import { useCreatePolicyContext } from '@/features/policies/CreatePolicy/context'
import { Link } from '@/lib/Link'
import { mq } from '@/lib/responsive'

const prevStyle = {
  bg: 'rgba(255, 115, 21, 0.72)',
}

const currentStyle = {
  bg: '#ff7315',
}

const nextStyle = {
  bg: '#1b2938',
}

const Pagination = () => {
  const {
    currentStep,
    totalStep,
    formSchema: { title },
  } = useCreatePolicyContext()
  const { query } = useRouter()

  const token = get(query, 'token')
  const step = +get(query, 'step')

  const indexArr = new Array(totalStep).fill(0).map((_, index) => index + 1)

  return (
    <Flex
      color="#ffffff"
      bg="#16202b"
      h="64px"
      rounded="8px"
      p="17px 24px"
      flexDirection="column"
      justifyContent="space-between"
      m="16px 0 24px"
    >
      <Heading as="h1" fontSize="12px" fontWeight="500">
        {title}
      </Heading>
      <Grid
        templateColumns={`repeat(${totalStep}, 1fr)`}
        css={{
          gap: '4px',

          [mq('sm')]: {
            gap: '8px',
          },
        }}
      >
        {indexArr.map((value) => {
          const style =
            value === step
              ? currentStyle
              : currentStep >= value
              ? prevStyle
              : nextStyle

          if (currentStep >= value) {
            return (
              <Link
                route="createPolicy"
                params={{ token, step: value }}
                passHref
                key={value}
              >
                <a>
                  <Box w="100%" height="4px" rounded="4px" {...style} />
                </a>
              </Link>
            )
          }

          return (
            <Box key={value} w="100%" height="4px" rounded="4px" {...style} />
          )
        })}
      </Grid>
    </Flex>
  )
}

export default Pagination
