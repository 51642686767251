import React from 'react'
import { Global, css } from '@emotion/react'
import { isEmpty } from 'lodash'
import { Flex, Box, Image, Grid } from '@chakra-ui/react'
import { useRouter } from 'next/router'

import { getRouteName, Link } from '@/lib/Link'
import { mq, Default, Mobile } from '@/lib/responsive'

import {
  Pagination,
  TransparencyBanner,
} from '@/features/policies/CreatePolicy/components'
import { ProkitBanner } from './ProkitBanner'
import { CustomerProfileBanner } from './CustomerProfileBanner'
import { CreatePolicyHeader } from './CreatePolicyHeader'

import { Info } from '@/features/policies/CreatePolicy/types'
import { useProfiles } from '../dashboard/profiles/services'

export type CreatePolicyLayoutProps = {
  children: React.ReactNode
  className?: string
  info?: Info
  isPremium?: boolean
  withBgImage?: boolean
  withPagination?: boolean
  profileName?: string | null
}

export const CreatePolicyLayout = ({
  children,
  className,
  info,
  isPremium = false,
  withBgImage = false,
  withPagination = false,
  profileName,
}: CreatePolicyLayoutProps): React.ReactElement => {
  const { asPath } = useRouter()

  useProfiles()

  return (
    <React.Fragment>
      <Global styles={GlobalStyles} />

      <Box
        m="0 auto 40px"
        css={{
          maxWidth: '336px',

          [mq('sm')]: {
            maxWidth: '536px',
          },

          [mq('md+')]: {
            maxWidth: '780px',
          },
        }}
      >
        <Flex
          position="relative"
          padding="30px 0"
          justifyContent="space-between"
          {...(withBgImage && { mb: '104px' })}
        >
          <Flex alignItems="center">
            <a href="/">
              <Default>
                <Image
                  src="/images/logo-alt.svg"
                  height="30px"
                  objectFit="cover"
                  cursor="pointer"
                  alt="PDPA Pro"
                />
              </Default>
              <Mobile>
                <Image
                  src="/images/logo-icon.svg"
                  height="30px"
                  objectFit="cover"
                  cursor="pointer"
                  alt="PDPA Pro"
                />
              </Mobile>
            </a>

            {withBgImage && (
              <Box
                position="absolute"
                zIndex="-1"
                background="no-repeat top/contain url(/images/Landing/img-desktop-policy.svg)"
                w="286px"
                h="187px"
                right="0px"
                bottom="-157px"
              />
            )}
          </Flex>
        </Flex>

        {withPagination && <Pagination />}

        {!isEmpty(info) && (
          <CreatePolicyHeader info={info as Info} isPremium={isPremium} />
        )}

        {profileName && <CustomerProfileBanner profileName={profileName} />}

        <Grid sx={{ gap: '16px', mt: 4 }}>
          <Box>
            <Box bg="#fff" borderRadius="8px" className={className}>
              {children}
            </Box>

            {getRouteName(asPath) === 'selectPolicy' && <ProkitBanner />}

            {getRouteName(asPath) !== 'selectPolicy' && <TransparencyBanner />}

            <Flex
              color="white"
              fontSize="10px"
              fontWeight="300"
              p="16px 0"
              justifyContent="space-between"
              css={{
                [mq('sm')]: {
                  fontSize: '14px',
                },
              }}
            >
              <Box>
                &copy; {new Date().getFullYear()} <strong>PDPA Pro.</strong> All
                Rights Reserved.
              </Box>
              <Box>
                <Link route="terms" passHref>
                  <a css={{ marginRight: '15px', color: '#fff' }}>
                    Term of use
                  </a>
                </Link>
                <Link route="privacy" passHref>
                  <a css={{ color: '#fff' }}>Privacy Policy</a>
                </Link>
              </Box>
            </Flex>
          </Box>
        </Grid>
      </Box>
    </React.Fragment>
  )
}

const GlobalStyles = css`
  body {
    background-color: #1b2938;
  }
`
