import { useState } from 'react'
import { Box, Button, Radio, RadioGroup, Stack, Text } from '@chakra-ui/react'
import { useRouter } from 'next/router'

import { Span } from '@/components/shared'

import { useProfilesData } from '@/features/dashboard/profiles/services'
import { useCreateFirstStep } from '../mutations'

import { getPolicyName } from '@/lib/helpers'
import { logEvent } from '@/lib/stats/gtm'

import { PolicyTemplate } from '../../types'

type SelectProfileProps = {
  template: PolicyTemplate
}

export const SelectProfile = (
  props: SelectProfileProps,
): React.ReactElement => {
  const { template } = props

  const [profile, setProfile] = useState('0')

  const profiles = useProfilesData()
  const { mutate: createPolicy, isLoading } = useCreateFirstStep()
  const router = useRouter()

  return (
    <Box sx={{ bg: 'white', borderRadius: '8px', p: 9, textAlign: 'center' }}>
      <Text sx={{ fontSize: '24px' }}>เลือกโปรไฟล์ที่ต้องการใช้งาน</Text>
      <Text sx={{ mt: 2 }}>
        ระบบจะทำการกรอกข้อมูลเกี่ยวกับบริษัทให้อัตโนมัติตามโปรไฟล์ที่คุณเลือก
      </Text>

      <RadioGroup
        name="profile"
        colorScheme="primary"
        size="lg"
        defaultValue="0"
        onChange={(value) => {
          setProfile(value)
        }}
      >
        <Stack spacing="4" sx={{ maxW: '460px', mx: 'auto', mt: '9' }}>
          <Box sx={{ p: 2 }}>
            <ProfileItem noProfile value="0" />
          </Box>

          {profiles && (
            <Stack
              sx={{
                p: 2,
                bg: 'gray.100',
                maxH: '240px',
                overflow: 'auto',
                borderRadius: '8px',
              }}
            >
              {profiles.map(({ name, id, name_count }) => {
                const _name = name_count > 0 ? `${name} (${name_count})` : name

                return (
                  <ProfileItem key={id} name={_name} value={id.toString()} />
                )
              })}
            </Stack>
          )}
        </Stack>
      </RadioGroup>

      <Button
        colorScheme="primary"
        isDisabled={!profile}
        sx={{ p: '16px 48px', h: '56px', mt: '36px', w: 'full' }}
        isLoading={isLoading}
        onClick={() => {
          createPolicy(
            {
              data: {
                template,
                ...(profile !== '0' && { profile_id: profile }),
              },
            },
            {
              onSuccess(policy) {
                const { token } = policy

                logEvent({
                  ga: {
                    category: 'Select policy',
                    action: 'Select policy',
                    label: getPolicyName(template),
                  },
                })

                router.push(`/create/policy/${token}/1`)
              },
            },
          )
        }}
      >
        ยืนยันการเลือกโปรไฟล์
      </Button>
    </Box>
  )
}

type ProfileItemProps = {
  name?: string
  value: string
  noProfile?: boolean
}

const ProfileItem = (props: ProfileItemProps): React.ReactElement => {
  const { name, value, noProfile = false } = props

  return (
    <Box
      sx={{
        bg: 'white',
        border: '1px solid',
        borderColor: 'customGray.300',
        borderRadius: '8px',
        p: 4,
        w: 'full',
        textAlign: 'start',
      }}
    >
      {noProfile ? (
        <Radio value={value}>
          <Span sx={{ fontSize: 'sm' }}>
            ไม่เลือกโปรไฟล์{' '}
            <Span sx={{ fontWeight: 'light' }}>
              (ฉันต้องการกรอกข้อมูลใหม่ทั้งหมด)
            </Span>
          </Span>
        </Radio>
      ) : (
        <Radio value={value}>
          <Span sx={{ fontSize: 'sm' }}>{name}</Span>
        </Radio>
      )}
    </Box>
  )
}
