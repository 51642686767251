import { ReactNode } from 'react'
import { Grid, Box } from '@chakra-ui/react'
import { Global, css } from '@emotion/react'

type Props = {
  children: ReactNode
}

export const AuthLayout = ({ children }: Props) => {
  return (
    <Box maxW="100vw" minH="100vh" bg="#1b2938" py="100px">
      <Global
        styles={css`
          a {
            color: #ff7315;
          }
        `}
      />
      <Grid
        w="620px"
        m="0 auto"
        gap="38px"
        justifyContent="center"
        justifyItems="center"
        gridTemplateColumns="620px"
      >
        <a
          href="/"
          css={{
            width: '169px',
            height: '40px',
            background: `no-repeat center/contain url(/images/logo-alt.svg)`,
            cursor: 'pointer',
          }}
        ></a>
        <Box bg="#ffffff" rounded="8px" w="100%">
          {children}
        </Box>
      </Grid>
    </Box>
  )
}
