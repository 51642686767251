import { get } from 'lodash'
import { fetchAPI } from '@/lib/api/helpers'

export const createFirstStep = ({ template = 'privacy', userData }) => {
  return fetchAPI({
    path: '/policies',
    method: 'post',
    data: { template },
    userData,
  }).then((res) => {
    return get(res, 'data.policy')
  })
}

export const createNewStep = (params = {}) => {
  const { fields = {}, token, step, userData } = params

  return fetchAPI({
    path: `/policies/${token}/steps/${step}`,
    method: 'put',
    data: {
      fields,
    },
    userData,
  }).then((res) => {
    return get(res, 'data.policy')
  })
}

export const getStep = (params = {}) => {
  const { token, step = 1, userData } = params

  return fetchAPI({
    path: `/policies/${token}/steps/${step}`,
    method: 'get',
    userData,
  }).then((res) => {
    return get(res, 'data.policy')
  })
}

export const getSummary = (params = {}) => {
  const { token, userData } = params

  return fetchAPI({
    path: `/policies/${token}/summary`,
    method: 'get',
    userData,
  }).then((res) => {
    return get(res, 'data.policy')
  })
}

export const confirmPolicy = ({ token, userData }) => {
  return fetchAPI({
    path: `/policies/${token}/confirm`,
    method: 'put',
    userData,
  }).then((res) => {
    return get(res, 'data')
  })
}

export const getOptions = ({ userData }) => {
  return fetchAPI({
    path: '/policy_options',
    method: 'get',
    userData,
  }).then((res) => {
    return get(res, 'data.policy_options')
  })
}
