import React, { useState } from 'react'
import { isEmpty } from 'lodash'
import {
  Box,
  Text,
  Heading,
  Image,
  Flex,
  Grid,
  Checkbox,
  Button,
  useDisclosure,
} from '@chakra-ui/react'

import { PolicyOption } from '@/features/policies/CreatePolicy/types'

import { mq } from '@/lib/responsive'

import { ExternalLink } from '@/components/Landing/shared'
import { PolicyIntroductionModal } from './PolicyIntroductionModal'

const selectedStyle = {
  container: {
    borderColor: '#ff7315',
    bg: 'rgba(255, 115, 21, 0.16)',
  },
  title: { color: '#ff7315' },
  subtitle: { color: '#2b2b2b' },
}

type SelectPolicyProps = {
  options: PolicyOption[]
  template: string
}

const SelectPolicy = ({
  options,
  template,
}: SelectPolicyProps): React.ReactElement => {
  const [selectedPolicy, setSelectedPolicy] = useState(template)
  const [consent, setConsent] = useState(false)

  const policyIntroductionModalDisclosure = useDisclosure()

  return (
    <Flex
      flexDirection="column"
      p="24px"
      m="0 auto"
      sx={{
        w: '100%',
        [mq('sm')]: {
          textAlign: 'center',
        },
      }}
    >
      <Box>
        <Heading
          as="h1"
          fontSize="24px"
          lineHeight="32px"
          color="#2b2b2b"
          fontWeight="500"
        >
          เลือก Policy ที่ต้องการสร้าง
        </Heading>
        {/* <Text color="#888888" mt="6px">
          กรุณาเลือก Policy ที่ต้องการ
          หรือ&nbsp;
          <ExternalLink
            href="https://datawow.typeform.com/to/dfj3Av6W"
            css={{ color: '#ff7315', textDecoration: 'underline' }}
          >
            ทำแบบทดสอบ
          </ExternalLink>
          &nbsp;เพื่อประเมิน Policy ที่เหมาะกับธุรกิจของคุณ
        </Text> */}
      </Box>

      <Grid
        my="16px"
        gap="16px"
        templateColumns="repeat(auto-fit, 136px)"
        justifyContent="center"
        sx={{
          [mq('sm')]: {
            my: '24px',
            gap: '24px',
            templateColumns: 'repeat(auto-fit, 140px)',
          },
        }}
      >
        {options.map(({ title, subtitle, slug }) => {
          const style =
            slug === selectedPolicy
              ? selectedStyle
              : ({} as Partial<typeof selectedStyle>)

          return (
            <Flex
              key={slug}
              sx={{
                boxSize: '136px',
                userSelect: 'none',
                rounded: '8px',
                p: '20px 0 14px',
                justifyContent: 'space-between',
                flexDir: 'column',
                border: '1px solid #e8e8e8',
                cursor: 'pointer',
                alignItems: 'center',
                [mq('sm')]: {
                  boxSize: '140px',
                },
                ...style.container,
              }}
              onClick={() => setSelectedPolicy(slug)}
            >
              <Image src={`/images/Landing/icon_${slug}.svg`} boxSize="56px" />
              <Text
                sx={{ fontSize: '12px', ...style.title, textAlign: 'center' }}
              >
                {title}
              </Text>
              <Text
                sx={{ color: '#888888', fontSize: '14px', ...style.subtitle }}
              >
                {subtitle}
              </Text>
            </Flex>
          )
        })}
      </Grid>

      <Text sx={{ fontSize: '18px', color: '#1b2938', fontWeight: 'light' }}>
        ชำระเงินหลังจากกรอกข้อมูลเรียบร้อย
      </Text>

      <Checkbox
        colorScheme="primary"
        my="20px"
        fontWeight="300"
        isChecked={consent}
        onChange={(e) => setConsent(e.target.checked)}
        css={{
          [mq('sm')]: {
            alignSelf: 'center',
          },
        }}
      >
        ฉันยอมรับ&nbsp;
        <ExternalLink
          href="/terms"
          css={{ color: '#2b2b2b', textDecoration: 'underline' }}
        >
          เงื่อนไขในการใช้เว็บไซต์
        </ExternalLink>
        &nbsp;และ&nbsp;
        <ExternalLink
          href="/privacy"
          css={{ color: '#2b2b2b', textDecoration: 'underline' }}
        >
          นโยบายความเป็นส่วนตัว
        </ExternalLink>
      </Checkbox>
      <Grid>
        <Button
          colorScheme="primary"
          p="16px 48px"
          h="56px"
          isDisabled={!consent || isEmpty(selectedPolicy)}
          onClick={() => {
            policyIntroductionModalDisclosure.onOpen()
          }}
        >
          {!consent
            ? 'จำเป็นต้องยอมรับเงื่อนไขการใช้เว็บไซต์และนโยบายความเป็นส่วนตัว'
            : 'ยืนยันการเลือก Policy'}
        </Button>
      </Grid>
      {selectedPolicy && (
        <PolicyIntroductionModal
          disclosure={policyIntroductionModalDisclosure}
          selectedPolicy={selectedPolicy}
        />
      )}
    </Flex>
  )
}

export default SelectPolicy
