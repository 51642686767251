import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Icon,
  Input,
  ListItem,
  ModalBody,
  OrderedList,
  Stack,
  Text,
  UseDisclosureReturn,
} from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { useRouter } from 'next/router'

import { Modal } from '@/components/Modal'

import { policyContents } from './constants'

import { useCreateFirstStep } from '../mutations'
import { useAuthContext } from '@/lib/auth/AuthContext'
import { useProfilesData } from '@/features/dashboard/profiles/services'

import { getPolicyName } from '@/lib/helpers'
import { logEvent } from '@/lib/stats/gtm'

interface Props {
  disclosure: UseDisclosureReturn
  selectedPolicy: string
}

export function PolicyIntroductionModal({
  disclosure,
  selectedPolicy,
}: Props): React.ReactElement {
  const { isAuth } = useAuthContext()
  const router = useRouter()

  const { mutate: createPolicy, isLoading } = useCreateFirstStep()
  const profiles = useProfilesData()

  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm()

  const onCreatePolicy = (email?: string) => {
    if (isAuth && profiles?.length) {
      router.push({
        pathname: '/create/policy/profile',
        query: {
          template: selectedPolicy,
        },
      })
    } else {
      createPolicy(
        {
          data: {
            template: selectedPolicy,
            email,
          },
        },
        {
          onSuccess(policy) {
            const { token } = policy

            logEvent({
              ga: {
                category: 'Select policy',
                action: 'Select policy',
                label: getPolicyName(selectedPolicy),
              },
            })

            router.push(`/create/policy/${token}/1`)
          },
        },
      )
    }
  }

  const currentPolicy = policyContents[selectedPolicy]

  return (
    <Modal
      {...disclosure}
      maxW="789px"
      withCloseButton
      isCentered
      scrollBehavior="inside"
      contentSX={{ maxH: 'unset' }}
    >
      <ModalBody sx={{ pt: 6 }}>
        <Stack sx={{ fontWeight: 'light' }} spacing="6">
          <Box sx={{ alignSelf: 'center', textAlign: 'center', mt: 4 }}>
            <Icon as={currentPolicy.icon} boxSize="90px" />
            <Text
              sx={{
                fontSize: '2xl',
                fontWeight: 'medium',
                mt: 3,
              }}
            >
              {currentPolicy.title}
            </Text>
          </Box>
          <Stack sx={{ maxH: '300px', overflowY: 'auto' }} spacing="6">
            {currentPolicy.content}

            <Box>
              <Text sx={{ fontWeight: 'medium' }}>
                ข้อมูลที่ต้องเตรียมสำหรับการสร้างนโยบายนี้
              </Text>
              <OrderedList sx={{ listStylePosition: 'inside', mt: 2 }}>
                {currentPolicy.requiredDocuments.map((doc) => (
                  <ListItem key={doc}>{doc}</ListItem>
                ))}
              </OrderedList>
            </Box>
          </Stack>

          {isAuth ? (
            <Button
              colorScheme="primary"
              isLoading={isLoading}
              size="xl"
              onClick={() => onCreatePolicy()}
            >
              เริ่มสร้าง Policy
            </Button>
          ) : (
            <form
              onSubmit={handleSubmit(({ email }) => {
                if (isSubmitting) return

                onCreatePolicy(email)
              })}
            >
              <Stack spacing="6">
                <FormControl isRequired>
                  <FormLabel>กรุณากรอกอีเมล์ของคุณเพื่อรับนโยบาย</FormLabel>
                  <Input
                    type="email"
                    placeholder="example@email.com"
                    size="lg"
                    {...register('email')}
                  />
                </FormControl>

                <Button
                  colorScheme="primary"
                  isLoading={isLoading}
                  size="xl"
                  type="submit"
                >
                  เริ่มสร้าง Policy
                </Button>
              </Stack>
            </form>
          )}
        </Stack>
      </ModalBody>
    </Modal>
  )
}
