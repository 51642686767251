import { useToast } from '@chakra-ui/react'
import { useMutation, useQueryClient } from 'react-query'
import { AxiosError } from 'axios'

import { useErrorHandler } from '@/lib/hooks'
import { fetchAPIv2 } from '@/lib/api'

import { PROFILES } from '../constants'
import { TOAST_CONFIG } from '@/lib/constants'

type Payload = {
  params: {
    id: string
  }
}

export const useDeleteProfile = () => {
  const queryClient = useQueryClient()
  const toast = useToast()
  const handleError = useErrorHandler()

  return useMutation<void, AxiosError, Payload>(
    async ({ params }) => {
      await fetchAPIv2<void>({
        path: `/profiles/${params.id}`,
        method: 'delete',
      })
    },
    {
      onSuccess() {
        queryClient.invalidateQueries([PROFILES])

        toast({
          ...TOAST_CONFIG,
          description: 'ลบโปรไฟล์สำเร็จ',
        })
      },
      onError(error) {
        handleError(error)
      },
    },
  )
}
