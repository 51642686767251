import { Flex, Text, HStack, VStack } from '@chakra-ui/react'
import * as d3 from 'd3-format'

import { CreatePolicyLayoutProps } from './CreatePolicyLayout'

export const CreatePolicyHeader = ({
  info,
  isPremium = false,
}: Required<Pick<CreatePolicyLayoutProps, 'info'>> & {
  isPremium?: boolean
}): React.ReactElement => {
  return (
    <Flex
      sx={{
        fontWeight: 'medium',
        flexFlow: {
          base: 'column nowrap',
          md: 'row nowrap',
        },
        justifyContent: 'space-between',
        alignItems: {
          base: 'stretch',
          md: 'center',
        },
        color: 'white',
        mb: {
          base: 6,
          md: 4,
        },
      }}
    >
      <HStack spacing="2">
        <Text
          sx={{
            fontSize: {
              base: '18px',
              'lg+': '24px',
            },
          }}
        >
          {info.title}
        </Text>
        <Text
          sx={{
            px: 1,
            py: '2px',
            backgroundImage: isPremium
              ? 'linear-gradient(290deg, #00e3ab, #32c4d7)'
              : 'none',
            backgroundColor: isPremium ? '#00e3ab' : '#ff7315',
            borderRadius: '7px 0',
            fontSize: '10px',
          }}
        >
          {isPremium ? 'Premium' : 'Free'}
        </Text>
      </HStack>
      <HStack
        spacing="2"
        sx={{
          justifyContent: {
            base: 'space-between',
            md: 'center',
          },
        }}
      >
        <VStack
          spacing="0"
          sx={{
            alignItems: {
              base: 'flex-start',
              md: 'flex-end',
            },
          }}
        >
          <Text
            sx={{
              fontSize: {
                base: '14px',
                'lg+': '16px',
              },
            }}
          >
            ราคา{' '}
            <Text
              as="span"
              sx={{
                fontWeight: 'light',
                fontSize: {
                  base: '10px',
                  'lg+': '12px',
                },
              }}
            >
              (ราคานี้รวม Vat 7%)
            </Text>
          </Text>
          <Text
            sx={{
              fontSize: {
                base: '12px',
                'lg+': '14px',
              },
              color: '#e4e4e4',
            }}
          >
            *ชำระเงินหลังจากกรอกข้อมูลเรียบร้อย
          </Text>
        </VStack>
        <Text
          sx={{
            color: '#1b2938',
            px: 4,
            py: 1,
            backgroundImage: 'linear-gradient(290deg, #00e3ab, #32c4d7)',
            borderRadius: '4px',
            fontSize: {
              base: '16px',
              md: '18px',
              'lg+': '20px',
            },
          }}
        >
          {d3.format(',')(isPremium ? info.premium : info.standard)}.-
        </Text>
      </HStack>
    </Flex>
  )
}
