import { Button, Box, useToast, Flex, Text, Grid } from '@chakra-ui/react'
import { get, isEmpty } from 'lodash'
import { useRouter } from 'next/router'

import { Link } from '@/lib/Link'
import { FormGenerator } from '@/features/formGenerator'
import { mq } from '@/lib/responsive'
import { logEvent } from '@/lib/stats/gtm'
import { getPolicyName } from '@/lib/helpers'

import { useCreatePolicyContext } from '../context'
import * as CreatePolicyServices from '../services'

function PolicyFormSection(): React.ReactElement | null {
  const toast = useToast()
  const { formSchema, totalStep, isEditing } = useCreatePolicyContext()
  const { query, push } = useRouter()

  const onSubmit = async (values: unknown) => {
    try {
      const { template } = await CreatePolicyServices.createNewStep({
        fields: values,
        token: query.token,
        step: query.step,
      })

      toast({
        title: 'Saved',
        status: 'success',
        isClosable: true,
        position: 'top',
      })

      if (isEditing) {
        push(`/confirm/policy/${query.token}`)
      } else {
        logEvent({
          ga: {
            category: 'Policy Q&A',
            action: 'Fill policy form success',
            label: `${getPolicyName(template)} | S${query.step}`,
          },
        })

        if (Number(query.step) < totalStep) {
          push(`/create/policy/${query.token}/${Number(query.step) + 1}`)
        } else {
          push(`/confirm/policy/${query.token}`)
        }
      }
    } catch {}

    window.scrollTo(0, 0)
  }

  if (isEmpty(formSchema)) {
    return null
  }

  return (
    <Box>
      <Box
        css={{
          padding: '24px 24px 16px',

          [mq('sm')]: {
            padding: '32px 32px 24px',
          },
        }}
      >
        <FormGenerator
          onSubmit={onSubmit}
          schema={formSchema}
          renderSubmitButton={() => <SubmitFormSection />}
        />
      </Box>
      {!isEditing && (
        <Flex
          w="100%"
          h="48px"
          bg="#f6f6f6"
          rounded="0 0 8px 8px"
          justifyContent="center"
          alignItems="center"
        >
          <Text fontSize="14px">
            <Link route="getPolicy" params={{ token: query.token }} passHref>
              <a>บันทึกข้อมูล</a>
            </Link>{' '}
            เพื่อดำเนินการต่อภายหลัง
          </Text>
        </Flex>
      )}
    </Box>
  )
}

const SubmitFormSection = () => {
  const router = useRouter()
  const { query } = router

  const step = parseInt(get(query, 'step', 1) as string)
  const token = get(query, 'token')

  const { isEditing } = useCreatePolicyContext()

  return (
    <Grid
      gap="20px"
      justifyContent="center"
      gridTemplateColumns="repeat(2, max-content)"
    >
      {isEditing ? (
        <Link
          route="confirmPolicy"
          params={{
            token,
          }}
          passHref
        >
          <Button
            as="a"
            size="lg"
            fontWeight="500"
            variant="outline"
            fontSize="16px"
          >
            ยกเลิกการแก้ไข
          </Button>
        </Link>
      ) : (
        !!step &&
        step > 1 && (
          <Link
            route="createPolicy"
            params={{
              token,
              step: Number(step - 1),
            }}
            passHref
          >
            <Button
              as="a"
              size="lg"
              fontWeight="500"
              variant="outline"
              fontSize="16px"
            >
              ก่อนหน้า
            </Button>
          </Link>
        )
      )}
      <Button
        type="submit"
        colorScheme="primary"
        size="lg"
        fontWeight="500"
        fontSize="16px"
      >
        {isEditing ? 'แก้ไขเสร็จสิ้น' : 'ถัดไป'}
      </Button>
    </Grid>
  )
}

export default PolicyFormSection
